import { Component, Vue, Model } from "vue-property-decorator";
import * as certifi from "@/utils/api";
import backHead from "@/components/backHead";
import { post } from "@/utils/http";
import axios from "axios";
import headbox from "@/components/head.vue";
import lrz from "lrz";
import Loadings from'@/components/Loadings.vue'

@Component({
  components: {
    backHead,
    headbox,
    Loadings
  }
})
export default class BindPhoneModel extends Vue {
  baiduBaseUrl = process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "test" ? "/baidubce" : "https://api.shenzhenyht.com/baidubce"
  fileBaseUrl =
    process.env.NODE_ENV == "dev"
      ? "/shenzhenyht"
      : "https://xinbaihe.oss-cn-shenzhen.aliyuncs.com";
  localtoken = localStorage.getItem("token");
  path:any = '';
  // 上传完毕下一步盒子打开
  nextBox = false;
  // 身份证反面是否显示确认
  public usercenterback: any = false;
  // 身份证信息
  public Idcard: any = "";
  public username: any = "";
  // 身份证正面下一步
  public nextBtn: any = false;
  // 身份证脸书
  idCardTime: any = "";
  idCardendTime: any = ""
  /**
   * 定义当前数据
   */
  usercenter: any = false;
  upsuccess = require("@/assets/images/pic_identity card_back@2x.png");
  firstupsuccess = require("@/assets/images/pic_identity card_front@2x.png");
  // 是否上传中
  isloading: any = false;

  isloadingback: any = false;
  apiKey: any = "np5Z2o86jTLStdZxCnFPm6Hm";
  secretKey: any = "XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A ";
  baidu_access_token: any = "";
  private RegisterForm: any = {
    loginAccount: "",
    password: "",
    phoneCode: ""
  };
  mounted() {
    // debugger;
    
    this.path =  this.$route.query.path?this.$route.query.path:'person'
    this.getAccess_token();
    // console.log('token',this.baidu_access_token);
  }
  // 身份证 (反面) 图片上传的回调
  private afterReadsecond(file:any) {
    console.log(file);
    const than = this;

    if (this.isloadingback) {
      return false;
    }
    this.isloading = true
    this.upsuccess = "";
    this.upsuccess = file.content;
    // let data = {
    //   image: encodeURI(this.upsuccess).slice(22),
    //   id_card_side: "back"
    // }
    //  // 获取文件上传地址
    // this.getUploadUrl(this.upsuccess,'back')

    // this.baiduAi_back(data)

    lrz(file.content, {
      quality: 0.2 //自定义使用压缩方式
    })
      .then(function(rst:any) {
        console.log(rst);
        const data = {
          image: encodeURI(rst.base64).slice(22),
          id_card_side: "back"
        };
        // 获取文件上传地址
        than.getUploadUrl(rst.base64, "back");
        than.baiduAi_back(data);
        //成功时执行
      })
      .catch((error:string) => {
        this.isloading = false;
        console.error(error);

        //失败时执行
      });
  }

  // 身份证 (正面) 图片上传的回调
  private afterRead(file:any): any {
    console.log(file.content);
    console.log(this.baidu_access_token);
    // debugger;
    const than = this;
    // if (this.isloading) {
    //   return false;
    // }
    this.isloading = true;
    // debugger;
    this.firstupsuccess = "";
    this.firstupsuccess = file.content;
    lrz(this.firstupsuccess, {
      quality: 0.2 //自定义使用压缩方式
    })
      .then(function(rst:any) {
        console.log('rst',rst);
        const data = {
          image: encodeURI(rst.base64).slice(22),
          id_card_side: "front"
        };
        // 获取文件上传地址
        than.getUploadUrl(rst.base64, "positive");
        than.baiduAi(data);
        //成功时执行
      })
      .catch((error:string) => {
        this.isloading = false;
        // debugger;
        //失败时执行
      });
  }
  // 身份证下一步
  private nextPage() {
    // debugger;
    if (!this.usercenter) {
      this.$toast("请重新上传身份证人像面");
      return
    }
    if (!this.usercenterback) {
      this.$toast("请重新上传身份证国徽面");
      return
    }else{

      this.$router.push({ name: "usercerti" });
    }
  }
  base64ToUint8Array(base64String:any) {
    // debugger;
    const base64clear = base64String.substring(base64String.indexOf(",") + 1);
    const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
    const base64 = (base64clear + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  //第一步（获取上传接口）
  getUploadUrl(path:any, type:any) {
    // debugger;
    const that = this;
    const data = {
      size: 1
    };
    certifi.apiFile(data).then((res:any) => {
      console.log('res',res);
      
      const key = res[0].key;
      localStorage.setItem(type, key);
      that.uploadFile(path, res[0].url);
    });
  }
  // 第二步上传文件
  uploadFile(imgpath:any, url:any) {
    // debugger;
    // 去掉url域名 保留后面
    const urllast =
      "/" +
      url
        .split("/")
        .slice(3)
        .join("/");
    const configs = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + this.localtoken
      }
    };
    const transformRequest = [
      function(data:any) {
        // debugger;
        let ret = "";
        for (const it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ];
    const imgarr = this.base64ToUint8Array(imgpath);
    axios({
      url: this.fileBaseUrl + urllast,
      method: "put",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + this.localtoken
      },
      data: imgarr
    }).then(res => {
      console.log("请求结果：", res);
      this.isloading = false
    });

    // axios.put(this.fileBaseUrl+urllast,imgarr,{configs,transformRequest}).then(res=>{
    //   console.log(res)
    // })
    // certifi.updaFileapi(this.fileBaseUrl+urllast,imgpath,{}).then(res=>{
    //   console.log(res)
    // })
  }

  // 百度获取API
  private getAccess_token() {
    
    // debugger;
    const that = this;
    // 百度识别API
    axios
      .post(
        `${this.baiduBaseUrl}/oauth/2.0/token?grant_type=client_credentials&client_id=np5Z2o86jTLStdZxCnFPm6Hm&client_secret=XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A`
      )
      .then(res => {
        console.log("res",res);
        
        const { access_token } = res.data;
        that.baidu_access_token = access_token;
        // this.baidu_access_token = res.data.data.access_token;
        console.log('tk',that.baidu_access_token);
      });
  }
  // 证件识别接口 - 反面
  baiduAi_back(data:any) {
    // debugger;
    const that = this;
    console.log('baidu_access_token',that.baidu_access_token);
    
    const token = that.baidu_access_token;
    const transformRequest = [
      function(data:any) {
        let ret = "";
        for (const it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ];

    const configheaders = {
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    };
    axios
      .post(
        `https://aip.baidubce.com/baidubce/rest/2.0/ocr/v1/idcard?access_token=` + token,
        data,
        { transformRequest }
      )
      .then(res => {
        console.log('res1',res);
        
        const image_status = res.data.image_status;
        if (!image_status) {
          that.isloading = false;
          console.log("image_status:" + image_status);
          return that.$toast("图片上传错误"), !1;
        }

        if (image_status == "normal") {
          this.usercenterback = true;
          let idCardTime = res.data.words_result.签发日期.words || "";
          idCardTime  =  idCardTime.substring(0,4) + '.'+idCardTime.substring(4,6) + '.'+idCardTime.substring(6, 8)+'-';
          let idCardendTime = res.data.words_result.失效日期.words || "";
          idCardendTime  =  idCardendTime.substring(0,4) + '.'+idCardendTime.substring(4,6) + '.'+idCardendTime.substring(6, 8) ;
          localStorage.setItem(
            "endtime",
            JSON.stringify({ endtime: idCardendTime })
          );
          this.idCardTime = idCardTime;
          this.idCardendTime = idCardendTime
          that.$toast("身份验证成功-反面");
          setTimeout(()=>{
            that.isloading = false;
          },1000)
          this.nextBox = true
          // 校验成功可以进行下一步
        } else if (image_status == "reversed_side") {
          that.$toast("身份证正反面颠倒");
          that.isloading = false;
        } else if (image_status == "non_idcard") {
          that.$toast("上传的图片中不包含身份证");
          that.isloading = false;
        } else if (image_status == "blurred") {
          that.$toast("身份证模糊");
          that.isloading = false;
        } else if (image_status == "other_type_card") {
          that.$toast("其他类型证照");
          that.isloading = false;
        } else if (image_status == "over_exposure") {
          that.$toast("身份证关键字段反光或过曝");
          that.isloading = false;
        } else if (image_status == "over_dark") {
          that.$toast("身份证欠曝（亮度过低）");
          that.isloading = false;
        } else if (image_status == "unknown") {
          that.$toast("未知状态");
          this.isloading = false;
        } else {
          console.log("image_status:" + image_status);
          that.$toast("图片上传错误");
          that.isloading = false;
        }
      }).catch((err=>{
        that.isloading = false;
      }));
  }
  // 证件识别接口 - 正面
  baiduAi(data:any) {
    console.log('data',data);
    
    const that = this;
    const token = that.baidu_access_token;
    const transformRequest = [
      function(data:any) {
        let ret = "";
        for (const it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ];
    console.log('transformRequest',transformRequest);
    

    const configheaders = {
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    };

    axios
      .post(
        `https://aip.baidubce.com/baidubce/rest/2.0/ocr/v1/idcard?access_token=` + token,
        data,
        { transformRequest }
      )
      .then(res => {
        console.log('res1',res);
        // https://aip.baidubce.com/rest/2.0/ocr/v1/idcard
        const image_status = res.data.image_status;
        if (!image_status) {
          that.isloading = false;
          console.log("image_status:" + image_status);
          return that.$toast("图片上传错误"), !1;
        }
        // const IdCard = res.data.words_result.公民身份号码.words || "";
        // const userName = res.data.words_result.姓名.words;
        const idCardTime = this.idCardTime;

        if (image_status == "normal") {
          that.username = res.data.words_result.姓名.words;;
          that.Idcard = res.data.words_result.公民身份号码.words || "";
          localStorage.setItem(
            "idcrad",
            JSON.stringify({
              name: res.data.words_result.姓名.words,
              IdCardnumber: res.data.words_result.公民身份号码.words || "",
              idCardTime: idCardTime
            })
          );
          console.log("身份验证成功");
          that.$toast("身份验证成功");
          setTimeout(()=>{
            that.isloading = false;
          },1000)
         
          that.usercenter = true;
          // 校验成功可以进行下一步
          that.nextBtn = true;
        } else if (image_status == "reversed_side") {
          this.$toast("身份证正反面颠倒");
          that.isloading = false;
        } else if (image_status == "non_idcard") {
          that.$toast("上传的图片中不包含身份证");
          that.isloading = false;
        } else if (image_status == "blurred") {
          that.$toast("身份证模糊");
          that.isloading = false;
        } else if (image_status == "other_type_card") {
          that.$toast("其他类型证照");
          that.isloading = false;
        } else if (image_status == "over_exposure") {
          that.$toast("身份证关键字段反光或过曝");
          that.isloading = false;
        } else if (image_status == "over_dark") {
          that.$toast("身份证欠曝（亮度过低）");
          that.isloading = false;
        } else if (image_status == "unknown") {
          that.$toast("未识别身份证,请重新拍照上传");
          that.isloading = false;
        }
      }).catch((err=>{
        that.isloading = false;
      }));
  }
}
